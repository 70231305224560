@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Major+Mono+Display&display=swap');

* {
  --primary: rgba(255, 255, 255, 1);
  --secondary: rgba(141, 141, 141, 0.65);
  --bg: rgba(0, 0, 0, 1);
  --logofont: 'Major Mono Display', monospace;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg);
  scroll-behavior: smooth;
  color: #fff;
  height: 100%;
  width: 100%;
}

/* GENERAL STYLING */
h1 {
  margin: 0;
}
h2 {
  font-size: 4rem;
  margin: 0;
}
a {
  color: inherit;
}
a:hover {
  text-decoration: underline;
}
a:active,
a:visited {
  text-decoration: none;
  color: inherit;
}
.btn {
  box-shadow: 0 0 0 0 #000000;
  background: #ffffff linear-gradient(to bottom, #ffffff 5%, #ffffff 100%);
  border-radius:42px;
  display:inline-block;
  cursor:pointer;
  color:#000000;
  font-size:17px;
  padding:14px 39px;
  text-decoration:none;
}
.btn:hover {
  background: #ffffff linear-gradient(to bottom, #ffffff 5%, #ffffff 100%);
}
.btn:active {
  position:relative;
  top:1px;
}
iframe {
  max-width: 100%;
  width: 100%;
  margin: 0;
  line-height: 1;
  border: none;
}
.header {
  font-size: 5rem;
  margin: 0;
  font-weight: 800;
  line-height: 1.25;
}
.subheader {
  font-size: 2rem;
  margin: 0;
  font-weight: 600;
  line-height: 1.25;
}
.nav {
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav .line {
  flex: 1;
  border-bottom: 2.5px solid rgba(255, 255, 255, 0.3)
}
.nav-content {
  display: flex;
  flex-direction: row;
}
/*.nav-content .logo {
  !*float: left;*!
  flex: 1;
}*/
.nav-content ul {
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-items {
  padding: 0;
  margin: 0;
}
.nav-items li {
  display: inline;
  float: left;
}
.nav-items li:hover {
  cursor: pointer;
}
.nav-items li a:hover {
  text-decoration: none;
}
.nav.hide {
  visibility: hidden;
  opacity: 0;
  transition: visibility 2s, opacity 2s linear;
}
.nav {
  visibility: visible;
  opacity: 1;
}
.nav .nav-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: bold;
}
.nav .nav-logo:hover {
  cursor: pointer;
}
.menu-opened .restletters {
  transition: all 0.3s ease-in;
  transition-delay: 0.25s;
}
.nav .nav-logo .small {
  font-size: 14px;
  display: none;
}
.nav .nav-logo, .nav-items li a {
  display: block;
  padding: 8px;
  /*width: 60px;*/
}
.hide {
  display: none;
}
.menu-bar {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  height: 50px;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  overflow: hidden;
  transition: all 0.5s ease-out, background 1s ease-out;
  transition-delay: 0.2s;
  z-index: 1;
}
.menu-bar .burger-container {
  position: relative;
  display: inline-block;
  height: 50px;
  width: 50px;
  cursor: pointer;
  transform: rotate(0deg);
  transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
.menu-bar .burger-container #burger {
  width: 18px;
  height: 8px;
  position: relative;
  display: block;
  margin: -4px auto 0;
  top: 50%;
}
.menu-bar .burger-container #burger .bar {
  width: 100%;
  height: 1px;
  display: block;
  position: relative;
  background: #fff;
  transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transition-delay: 0s;
}
.menu-bar .burger-container #burger .bar.topBar {
  transform: translateY(0px) rotate(0deg);
}
.menu-bar .burger-container #burger .bar.btmBar {
  transform: translateY(6px) rotate(0deg);
}
.menu-bar .icon {
  /*display: inline-block;*/
  position: fixed;
  top: 0;
  left: 50%;
  line-height: 50px;
  /*width: 50px;*/
  height: 50px;
  text-align: center;
  color: #fff;
  font-size: 22px;
  font-weight: bold;
  transform: translateX(-50%);
}
.menu-bar .icon.icon-bag {
  right: 0;
  top: 0;
  left: auto;
  transform: translateX(0px);
  transition: transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transition-delay: 0.65s;
}
.menu-bar ul.menu {
  position: relative;
  display: block;
  padding: 0 48px 0;
  list-style: none;
}
.menu-bar ul.menu li.menu-item {
  border-bottom: 1px solid #333;
  margin-top: 5px;
  transform: scale(1.15) translateY(-30px);
  opacity: 0;
  transition: transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99), opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99);
}
.menu-bar ul.menu li.menu-item:nth-child(1) {
  transition-delay: 0.49s;
}
.menu-bar ul.menu li.menu-item:nth-child(2) {
  transition-delay: 0.42s;
}
.menu-bar ul.menu li.menu-item:nth-child(3) {
  transition-delay: 0.35s;
}
.menu-bar ul.menu li.menu-item:nth-child(4) {
  transition-delay: 0.28s;
}
.menu-bar ul.menu li.menu-item:nth-child(5) {
  transition-delay: 0.21s;
}
.menu-bar ul.menu li.menu-item:nth-child(6) {
  transition-delay: 0.14s;
}
.menu-bar ul.menu li.menu-item:nth-child(7) {
  transition-delay: 0.07s;
}
.menu-bar ul.menu li.menu-item a {
  display: block;
  position: relative;
  text-decoration: none;
  font-size: 22px;
  line-height: 2.35;
  font-weight: 500;
  width: 100%;
}
.menu-bar.menu-opened {
  height: 100%;
  background-color: #000;
  transition: all 0.3s ease-in, background 0.5s ease-in;
  transition-delay: 0.25s;
}
.menu-bar.menu-opened .burger-container {
  transform: rotate(90deg);
}
.menu-bar.menu-opened .burger-container #burger .bar {
  transition: all 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transition-delay: 0.2s;
}
.menu-bar.menu-opened .burger-container #burger .bar.topBar {
  transform: translateY(4px) rotate(45deg);
}
.menu-bar.menu-opened .burger-container #burger .bar.btmBar {
  transform: translateY(3px) rotate(-45deg);
}
.menu-bar.menu-opened ul.menu li.menu-item {
  transform: scale(1) translateY(0px);
  opacity: 1;
}
.menu-bar.menu-opened ul.menu li.menu-item:nth-child(1) {
  transition-delay: 0.27s;
}
.menu-bar.menu-opened ul.menu li.menu-item:nth-child(2) {
  transition-delay: 0.34s;
}
.menu-bar.menu-opened ul.menu li.menu-item:nth-child(3) {
  transition-delay: 0.41s;
}
.menu-bar.menu-opened ul.menu li.menu-item:nth-child(4) {
  transition-delay: 0.48s;
}
.menu-bar.menu-opened ul.menu li.menu-item:nth-child(5) {
  transition-delay: 0.55s;
}
.menu-bar.menu-opened ul.menu li.menu-item:nth-child(6) {
  transition-delay: 0.62s;
}
.menu-bar.menu-opened ul.menu li.menu-item:nth-child(7) {
  transition-delay: 0.69s;
}
.menu-bar.menu-opened .icon.icon-bag {
  transform: translateX(75px);
  transition-delay: 0.3s;
}
/*.menu-bar {
  margin: 11px 0 0 11px;
  position: fixed;
  z-index: 2;
  display: none;
}
.menu-bar:hover {
  cursor: pointer;
}
.menu-bar .beacon {
  width: 50px;
  height: 50px;
}
.menu-bar .top-bar {
  position: absolute;
  background-color: transparent;
  top: 0;
  width: 40px;
  height: 7.5px;
  display: block;
  !*transform: rotate(35deg);*!
  float: right;
  border-radius: 2px;
  transform-origin: center center;
}
.menu-bar .top-bar:after {
  content: "";
  background-color: white;
  width: 40px;
  height: 7.5px;
  display: block;
  float: right;
  border-radius: 10px;
  transition: all 0.5s cubic-bezier(0.25, 1.7, 0.35, 0.8);
  z-index: -1;
}
.menu-bar .middle-bar {
  position: absolute;
  background-color: transparent;
  top: 12px;
  width: 40px;
  height: 7.5px;
  display: block;
  !*transform: rotate(35deg);*!
  float: right;
  border-radius: 2px;
  transform-origin: center center;
}
.menu-bar .middle-bar:after {
  content: "";
  background-color: white;
  width: 40px;
  height: 7.5px;
  display: block;
  float: right;
  border-radius: 10px;
  transition: all 0.5s cubic-bezier(0.25, 1.7, 0.35, 0.8);
  z-index: -1;
}
.menu-bar .bottom-bar {
  position: absolute;
  background-color: transparent;
  top: 24px;
  width: 40px;
  height: 7.5px;
  display: block;
  !*transform: rotate(35deg);*!
  float: right;
  border-radius: 2px;
  transform-origin: center center;
}
.menu-bar .bottom-bar:after {
  content: "";
  background-color: white;
  width: 40px;
  height: 7.5px;
  display: block;
  float: right;
  border-radius: 10px;
  transition: all 0.5s cubic-bezier(0.25, 1.7, 0.35, 0.8);
  z-index: -1;
}*/
/*.menu-bar.open .top-bar:after {
  transform: rotate(90deg);
}
.menu-bar.open .middle-bar:after {
  transform: rotate(45deg);
}
.menu-bar.open .bottom-bar:after {
  transform: rotate(-45deg);
}*/
.icon {

}

/* SECTION SPECIFIC STYLING */
section {
  min-height: 100vh;
}
section .header {
  display: flex;
  text-align: center;
  align-items: center;
  /*justify-content: center;*/
  font-size: 50px;
}
section .header .icon {
  margin-left: 15px;
  text-align: center;
  height: 3.5rem;
}
.section-2 .header, .section-3 .header {
  margin-top: 140px;
  margin-bottom: 90px;
}
.section-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.section-1 .header {
  font-size: 5rem;
}
.section-2 {
  display: flex;
  flex-direction: column;
  padding: 0;
  align-items: center;
  /*justify-content: center;*/
}
.section-2 .header.second {
  margin-bottom: 0;
}
.section-2 .subheader {
  font-size: 12px;
  margin-bottom: 300px;
  text-align: center;
  font-weight: normal;
}
.section-2 .column {
  display: grid;
  grid-column-gap: 50px;
  grid-row-gap: 50px;
  padding-bottom: 24px;
  grid-template-columns: repeat(2,1fr);
}
.section-2 .column.single {
  grid-template-columns: repeat(1,1fr);
}
.section-2 .column div {
  max-width: 384px;
  width: 100%;
}
.section-2 .project-single {
  max-width: 384px;
  width: 100%;
  max-height: 225.4px;
  height: 100%;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
}
.section-2 .column a {
  text-decoration: none;
}
.section-2 .column h3, p {
  margin: 0;
}
.section-2 .column p {
  font-size: 12px;
  /*margin-bottom: 10px;*/
}
.section-2 .column .view {
  /*margin-top: 20px;*/
  display: none;
}
.wrapper {
  margin: 0 auto;
  padding: 0 16.66% 50px;
  width: 100%;
}
article {
  position: relative;
  max-width: 980px;
  margin: 0 auto;
}
.timeline__nav {
  position: fixed;
  z-index: 99;
  top: 0;
  transition: top 0.3s ease-out;
}
.timeline__nav ul {
  list-style: none;
  list-style-position: inside;
  margin: 15px 0;
}
.timeline__nav ul li {
  margin: 15px 0;
  padding-left: 0;
  list-style-type: none;
  color: #bfc1c3;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: all 0.3s ease-out;
}
.timeline__nav ul li.active {
  font-weight: bold;
  color: #f94125;
  border-bottom: 1px dotted transparent;
  transform: scale(1.2);
}
.timeline__nav ul li:hover {
  color: #000;
}

.section-3 {
  display: flex;
  flex-direction: column;
  padding: 0;
  align-items: center;
  /*justify-content: center;*/
}
.section-3 .contact-options {
  /*text-align: center;*/
  /*align-items: center;*/
  /*justify-content: center;*/
  max-width: 2500px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 200px;
}
.section-3 .contact-options .box {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}
.section-3 .openhours {
  margin-top: auto;
  margin-bottom: 30px;
}
.section-3 .calendly {
  width: 100%;
}
.section-3 .calendly .iframe {
  height: 900px;
  width: 100%;
}
/*.section-3 .calendly .iframe {
  display: none;
}*/
.section-3 .calendly .button {
  display: none;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
  text-align: center;
  color: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ADDONS */
.arrow-icon {
  background-color: transparent;
  height: 5px;
  width: 50px;
  display: block;
  padding: 0.5em;
  cursor: pointer;
  border-radius: 4px;
  position: fixed;
  bottom: 5px;
}
.arrow-icon .left-bar {
  position: absolute;
  background-color: transparent;
  top: 0;
  left: 0;
  width: 40px;
  height: 10px;
  display: block;
  /*transform: rotate(35deg);*/
  float: right;
  border-radius: 2px;
  transform-origin: center center;

}
.arrow-icon .left-bar:after {
  content: "";
  background-color: white;
  width: 40px;
  height: 10px;
  display: block;
  float: right;
  border-radius: 6px 10px 10px 6px;
  transition: all 0.5s cubic-bezier(0.25, 1.7, 0.35, 0.8);
  z-index: -1;
}
.arrow-icon .right-bar {
  position: absolute;
  background-color: transparent;
  top: 0;
  left: 26px;
  width: 40px;
  height: 10px;
  display: block;
  /*transform: rotate(-35deg);*/
  float: right;
  border-radius: 2px;
  transform-origin: center center;

}
.arrow-icon .right-bar:after {
  content: "";
  background-color: white;
  width: 40px;
  height: 10px;
  display: block;
  float: right;
  border-radius: 10px 6px 6px 10px;
  transition: all 0.5s cubic-bezier(0.25, 1.7, 0.35, 0.8);
  z-index: -1;
}
.arrow-icon.open .left-bar:after {
  transform: rotate(35deg);
}
.arrow-icon.open .right-bar:after {
  transform: rotate(-35deg);
}

.box {
  background-color: var(--secondary);
  border-radius: 20px;
}

@media (max-width: 1450px) {
  .section-2 .header, .section-3 .header {
    margin-top: 70px;
    margin-bottom: 75px;
  }
  .section-3 .contact-options {
    margin-bottom: 0;
    grid-gap: 20px;
  }
}

@media (max-width: 768px) {
  .menu-bar {
    display: block;
    position: fixed;
  }
  .nav-items {
    display: none!important;
  }
  section .header .icon {
    height: 3rem;
    display: none;
  }
  .section-1 .header {
    font-size: 2.5rem;
  }

  .section-1 .subheader {
    font-size: 1.1rem;
  }

  .section-2 .header, .section-3 .header {
    margin-top: 75px;
    margin-bottom: 0;
    font-size: 2.7rem;
  }
  .section-2 .header.second {
    font-size: 2.3rem;
    margin-top: 25px!important;
  }

  .section-2 .subheader {
    margin-bottom: 20px;
    width: 80%;
  }

  .section-2 .column {
    text-align: center;
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 75px;
  }
  .section-2 .column {
    width:80%;
  }
  .section-2 .project-single {
    height:60%;
  }

  .section-3 .header.second {
    font-size: 2.3rem;
    margin-bottom: 30px;
  }

  .section-3 .calendly .iframe {
    display: none;
  }
  .section-3 .calendly .button {
    display: block;
  }

  .section-3 .contact-options {
    display: block;
    margin-bottom: 50px;
  }

  .section-3 .contact-options div {
    margin-bottom: 0;
  }

  .menu-bar .beacon {
    width: 2rem;
    height: 2rem;
  }

  .section-3 .contact-options .box.mail {
  }
}